import React from "react";
import { Link } from "gatsby";
import { Row, Col, Button, Card } from "react-bootstrap";
import Video from "@components/Video";
import { measurementVideos } from "@utils/static-data";

import styles from "./measurements.module.scss";

const Measurements = () => {
    const renderMeasurementVideos = () => {
        let newMeasurementData = []
            .concat(measurementVideos)
            .slice(0, 4)
            .reverse();
        return newMeasurementData.map((video, idx) => (
            <Col key={idx} xs={12} md={3} className={video.class}>
                <Card className={styles.measurementVideo}>
                    <Card.Body className="p-0">
                        <Video title={video.title} link={video.link} />
                    </Card.Body>
                </Card>
                <p className={styles.videoTitle}>{video.title}</p>
            </Col>
        ));
    };

    return (
        <section className="how-to-measure mb-5">
            <Row className="mb-4 align-items-end">
                <Col md={4}>
                    <h4 className={styles.title}>VIDEO CARA PENGUKURAN</h4>
                </Col>
                <Col
                    md={6}
                    className="d-none d-md-block"
                    style={{ borderBottom: "3px solid #000" }}
                ></Col>
                <Col xs={12} md={2} className="d-none d-md-block">
                    <Button
                        className={styles.btnMore}
                        as={Link}
                        to="/standar-ukuran"
                    >
                        LIHAT SEMUA
                    </Button>
                </Col>
            </Row>
            <Row>{renderMeasurementVideos()}</Row>
            <Row className="d-md-none">
                <Col xs={12}>
                    <Button
                        className={styles.btnMore}
                        as={Link}
                        to="/standar-ukuran"
                    >
                        LIHAT SEMUA
                    </Button>
                </Col>
            </Row>
        </section>
    );
};

export default Measurements;
