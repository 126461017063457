import React, { useState, useRef, useLayoutEffect } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Container } from "react-bootstrap";
import BackgroundImage from "gatsby-background-image";
import Layout from "@components/Layout";
import {
    VariantPolo,
    Benefits,
    Measurements,
    HeroHeader,
    MaterialDesc,
} from "@components/sections";

import homeStyles from "@cssmodules/home.module.scss";

export default function Home() {
    const { desktopBG, mobileBG } = useStaticQuery(graphql`
        query HeroQuery {
            desktopBG: file(relativePath: { eq: "bg/bg-hero-desktop.jpg" }) {
                childImageSharp {
                    fixed(width: 2024, height: 1047, quality: 90) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            mobileBG: file(relativePath: { eq: "bg/bg-hero-mobile.jpg" }) {
                childImageSharp {
                    fixed(width: 980, height: 1450, quality: 90) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
        }
    `);

    const bg = [
        mobileBG.childImageSharp.fixed,
        {
            ...desktopBG.childImageSharp.fixed,
            media: "(min-width: 992px)",
        },
    ];

    const [bgSize, setBgSize] = useState("");
    const [textOpacity, setTextOpacity] = useState(1);

    const sectionRef = useRef(null);

    useLayoutEffect(() => {
        const onScroll = () => {
            if (
                window.innerWidth >= 991 &&
                window.scrollY <= window.innerHeight
            ) {
                const bgsize = Number(
                    120 - (window.scrollY / window.innerHeight) * 20
                ).toFixed(1);
                setBgSize(bgsize + "%");
            } else if (window.innerWidth < 991) {
                setBgSize("cover");
            }

            if (window.scrollY <= window.innerHeight + 10 / 3) {
                const opacity = Number(
                    1 - window.scrollY / (window.innerHeight / 3)
                ).toFixed(2);
                setTextOpacity(opacity);
            }
        };

        onScroll();

        window.addEventListener("scroll", onScroll);
        return () => {
            window.removeEventListener("scroll", onScroll);
        };
    }, []);

    return (
        <Layout>
            <BackgroundImage
                tag="main"
                id="home"
                className={homeStyles.main}
                style={{
                    backgroundSize: bgSize,
                }}
                fixed={bg}
            >
                <HeroHeader textOpacity={textOpacity} />
                <section
                    ref={sectionRef}
                    className={`py-5 bg-white w-100 ${homeStyles.section}`}
                >
                    <Container>
                        <VariantPolo />
                        <Benefits />
                        <MaterialDesc />
                        <Measurements />
                    </Container>
                </section>
            </BackgroundImage>
        </Layout>
    );
}
