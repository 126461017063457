import React from "react";
import { Row, Col } from "react-bootstrap";

import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

import styles from "./variant.module.scss";

const VariantPolo = () => {
    const { variantRompiMobile, variantRompiDesktop } = useStaticQuery(graphql`
        query VariantRompi {
            variantRompiMobile: file(
                relativePath: { eq: "products/rompi-04.jpg" }
            ) {
                childImageSharp {
                    fluid(maxWidth: 860, quality: 95) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            variantRompiDesktop: file(
                relativePath: { eq: "products/rompi-03.jpg" }
            ) {
                childImageSharp {
                    fluid(maxWidth: 1621, quality: 95) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `);

    const variantRompi = [
        variantRompiMobile.childImageSharp.fluid,
        {
            ...variantRompiDesktop.childImageSharp.fluid,
            media: "(min-width: 992px)",
        },
    ];

    return (
        <section className="product-list mb-4">
            <h2 className={styles.title}>
                #Konveksi Rompi Online No.1 Di Indonesia
            </h2>
            <Row>
                <Col>
                    <Img
                        fluid={variantRompi}
                        className={styles.poloImg}
                        imgStyle={{ objectFit: "contain" }}
                        alt="variant-rompi"
                    />
                </Col>
            </Row>
        </section>
    );
};

export default VariantPolo;
